import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ScreenWrapper, HeadingWrapper, TextWrapper, FlexWrapper, FlagWrapper } from "../components/contentwrappers"
import BackgroundImage from "gatsby-background-image"

import ActionButton from "../components/actionbutton"


const SecondPage = props => (
  <>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{ height: "100vh", width: "100vw", position: "fixed", top: "0", left: "0", zIndex: "-9" }}
    >
    </BackgroundImage>

    <Layout>
      <SEO title="Services" />
      <ScreenWrapper>
        <HeadingWrapper>
          <h1>Services For Our <strong>Clients</strong></h1>
        </HeadingWrapper>
        <TextWrapper>
          <p>At LSNIFA we have developed a number of bespoke propositions for our clients. All have the same quality hallmarks and characteristics of delivering a value for money proposition tailored to your needs underpinned by institutional quality solutions and impartial, expert advice.</p>
        </TextWrapper>

        <FlexWrapper>
          <FlagWrapper>
            <h2><strong>Private Client</strong> Proposition</h2>
          Our flagship propositon offering institutional quality financial planning and wealth management.
          <ActionButton toRef="/services-for-clients#private-client-proposition">Find out <strong>More</strong></ActionButton>
          </FlagWrapper>
          <FlagWrapper>
            <h2><strong>Adviser Assist</strong> Programme</h2>
            Meeting the ad-hoc needs of our clients with expert advice and best of breed solutions.
            <ActionButton toRef="/services-for-clients#adviser-assist-programme">Find out <strong>More</strong></ActionButton>
          </FlagWrapper>
          {/*}
          <FlagWrapper>
            <h2><strong>Self Serve</strong> Proposition</h2>
            Providing you with the tools to help control and manage your own financial affairs.
            <ActionButton toRef="/services-for-clients#self-serve-programme">Find out <strong>More</strong></ActionButton>
          </FlagWrapper>
          */}
        </FlexWrapper>
      </ScreenWrapper>


      <ScreenWrapper id="private-client-proposition">
        <TextWrapper >
          <h2><strong>Private Client</strong> Proposition</h2>
          <p>This is our flagship proposition. We have invested in our business to be able to provide our clients with the quality of financial planning and wealth management proposition to rival that offered by any leading Independent Financial Advisers anywhere in the UK.

It encompasses:</p>

          <ul>
            <li>Construction of a bespoke financial plan to give you clarity over what's needed to achieve your financial objectives.</li>
            <li>We will invest time understanding your objectives &amp; priorities, use leading industry tools to assess tolerance &amp; capacity for investment risk and examine income &amp; expenditure requirements.</li>
            <li>The advice will encompass key areas of your financial planning and wealth management requirements including advice on investment management, estate planning*, retirement advice, protection issues and mortgages.</li>
            <li>Once your plan is established we will proactively review it with you each year to keep it on track and keep abreast of any challenges or opportunities that present themselves.</li>
          </ul>

          <p>* Estate planning is not regulated by the Financial Conduct Authority.</p>

          <div style={{ textAlign: "center" }}>
            <ActionButton toRef="/contact"><strong>Contact Us</strong> to find out more...</ActionButton>
          </div>
        </TextWrapper>
      </ScreenWrapper>
      <ScreenWrapper id="adviser-assist-programme">
        <TextWrapper>
          <h2><strong>Adviser Assist</strong> Programme</h2>
          <p>Many of our clients have simpler or more ad-hoc needs and so we have developed our Adviser Assist programme to make sure we can cater for their needs in a more reactive fashion.</p>

          <p>This in no way means the advice or the solutions provided are of inferior quality, it simply reflects our desire to deliver a service that will be more affordable to clients with simpler needs.</p>

          <p>All Adviser Assist clients will benefit from our expert advice and best of breed solutions. Wherever we receive ongoing adviser charging fees we will also provide a proactive review service.</p>

          <div style={{ textAlign: "center" }}>
            <ActionButton toRef="/contact"><strong>Contact Us</strong> to find out more...</ActionButton>
          </div>
        </TextWrapper>

      </ScreenWrapper>
      {/*
      <ScreenWrapper id="self-serve-programme">
        <TextWrapper>
          <h2><strong>Self Serve</strong> Programme</h2>
          <p>Many of our clients have very straightforward requirements or wish to take more responsibility for arranging and managing their own affairs. As part of our commitment to helping solicitors' clients we designed this service so that the fees associated with face to face regulated investment advice would not become a barrier or prohibitive to us helping solicitors to help their clients.</p>

          <p>This service is provided mainly by phone and post and/or through our website.</p>
          <div style={{ textAlign: "center" }}>
            <ActionButton toRef="/self-serve">Find out <strong>More</strong> </ActionButton>
          </div>
        </TextWrapper>

      </ScreenWrapper>
      */}
    </Layout >
  </>
)

export default SecondPage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(relativePath: { eq: "royal-courts-of-justice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5031, quality:60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
